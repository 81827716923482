import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the type for the context state
interface AvatarContextType {
    avatars: { [key: string]: string }; // Dictionary to hold multiple avatars
    setAvatar: (profileId: number, avatarUrl: string) => void; // Function to set an avatar
}

// Create the context with a default value
const AvatarContext = createContext<AvatarContextType | undefined>(undefined);

// Define the AvatarProvider component
interface AvatarProviderProps {
    children: ReactNode; // Type for children prop
}

export const AvatarProvider: React.FC<AvatarProviderProps> = ({ children }) => {
    const [avatars, setAvatars] = useState<{ [key: string]: string }>({}); // State to store avatars

    const setAvatar = (profileId: number, avatarUrl: string) => {
        setAvatars((prev) => ({ ...prev, [profileId]: avatarUrl }));
    };

    return (
        <AvatarContext.Provider value={{ avatars, setAvatar }}>
            {children}
        </AvatarContext.Provider>
    );
};

// Custom hook to use the AvatarContext
export const useAvatar = (): AvatarContextType => {
    const context = useContext(AvatarContext);
    if (!context) {
        throw new Error("useAvatar must be used within an AvatarProvider");
    }
    return context;
};
