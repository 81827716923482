// ProfileContext.tsx
import React, { createContext, useEffect, useState, useContext } from "react";
import { ProfileModel } from "./Components/Models/ProfileModel";
import { URLHelper } from "./Helpers/URLHelper";
import { useNavigate } from "react-router-dom";

// Create the context
const ProfileContext = createContext<any>(null);

// Create a provider component
export const ProfileProvider: React.FC<{ children: React.ReactNode }> = ({
   children,
}) => {
   const [currentProfile, setCurrentProfile] = useState<any>();
   const [currentUser, setCurrentUser] = useState<any>();
   const [errorMessage, setErrorMessage] = useState("");
   const navigate = useNavigate();
   useEffect(() => {
      try {
         const checkAuthenticatedUser = async () => {
            const token = localStorage.getItem("token"); // Retrieve the token from storage
            if (!token) {
               // Handle case where there is no token
               return;
            }
            const getUserProfile = await URLHelper.GetAction(
               `profile/getUserProfile`,
               {
                  headers: {
                     Authorization: `Bearer ${token}`,
                     "Content-Type": "application/json",
                  },
               }
            )
               .then((e) => {
                  setCurrentUser(e);
                  setCurrentProfile(e);
               })
               .catch((e) => {
                  localStorage.removeItem("token");
                  // Check if the history length is greater than 1 (to ensure there is a valid page to go back to)
                  if (window.history.length > 1) {
                     window.history.back(); // Go back to the previous page
                  } else {
                     // If there's no valid page to go back to, redirect to a fallback route, e.g., login page or homepage
                     window.location.href = "/"; // Redirect to the home or login page
                  }
               });
         };
         checkAuthenticatedUser();
      } catch (e) {
         // Set the error message from the response data
         setErrorMessage((e as any).response.data || "An error occurred.");
      }
   }, []);

   return (
      <ProfileContext.Provider value={{ currentProfile, setCurrentProfile }}>
         {children}
      </ProfileContext.Provider>
   );
};

// Custom hook to use the ProfileContext
export const useProfile = () => {
   return useContext(ProfileContext);
};
