// s3Config.js
const AWS = require('aws-sdk');

const spacesEndpoint = new AWS.Endpoint('https://sfo3.digitaloceanspaces.com'); // Replace with your Space's endpoint

const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    credentials: {
        accessKeyId: process.env.REACT_APP_DO_SPACE_ACCESS_KEY, // Access key pair. You can create access key pairs using the control panel or API.
        secretAccessKey: process.env.REACT_APP_DO_SPACE_SECRET_KEY // Secret access key defined through an environment variable.
      }
});

export default s3; // Export s3 instance
