import axios, { AxiosResponse } from "axios";
import s3 from "../s3Config";
var baseURL =
   process.env.NODE_ENV == "development"
      ? process.env.REACT_APP_URL_DEVELOPMENT
      : process.env.REACT_APP_URL_PRODUCTION;

      type ResponseType = 'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream' | undefined;

export const URLHelper = {

   GetAction: async (endpoint: string, options = {}) => {
      const url = `${baseURL}/${endpoint}`;
      const response = await fetch(url, {
         method: "GET", // Change this to POST if needed
         ...options, // Spread in any additional options (like headers)
      });

      return response.json(); // Return the parsed JSON
   },
   PostAction: async (endpoint: string, body: any, headers: Record<string, string> = {}, responseType: ResponseType = 'json'): Promise<AxiosResponse<any>> => {
      return await axios.post(`${baseURL}/${endpoint}`, body, {
         headers: {
            "Content-Type": "application/json",
            ...headers // Spread any additional headers provided
         },
         responseType: responseType // Set the response type dynamically
      });
   },
   UploadImage: async (file: any) => {
      const params = {
         Bucket: "truck-buddy-cdn", // Replace with your Space name
         Key: file.name, // The name you want to give the uploaded file
         Body: file.data, // The image file's data
         ACL: "public-read", // Optional: Make it publicly accessible
         ContentType: file.mimetype, // Set the content type
      };

      try {
         const data = await s3.upload(params).promise();
      } catch (error) {
         console.error("Error uploading file:", error);
      }
   },
   GenerateImageUrl: async (profile_id: number) => {
      const response = await URLHelper.PostAction("profile/avatar", { profile_id: profile_id }, {}, 'blob');
      // Convert the response to a blob
      
      return URL.createObjectURL(response.data);
   },
   SetAvatar: async (profile_id: string, user_id: string, avatarFile: File) => {
      // Create a FormData object to send the profile ID and file
      const formData = new FormData();
      formData.append("profile_id", profile_id);
      formData.append("user_id", user_id);
      formData.append("avatar_file", avatarFile);
  
      try {
          // Send the POST request to upload the avatar
          const response = await URLHelper.PostAction("profile/setAvatar", formData, {
            'Content-Type': 'multipart/form-data'
          });
  
          if (response.status === 200) {
          } else {
              console.error("Failed to upload avatar:", response);
          }
      } catch (error) {
          console.error("Error uploading avatar:", error);
      }
  }
};
