// SignalRContext.tsx
import React, { createContext, useEffect, useState, ReactNode } from "react";
import * as signalR from "@microsoft/signalr";
import { MessageModel } from "./Components/Models/MessageModel";
import { ConversationModel } from "./Components/Models/ConversationModel";
import { useProfile } from "./ProfileContext";

interface SignalRContextProps {
    connection: signalR.HubConnection | null;
    messages: MessageModel[];
    conversations: ConversationModel[] | undefined;
    setConversations: React.Dispatch<React.SetStateAction<ConversationModel[] | undefined>>;
    sendMessage: (conversationId: number, messageText: string, senderId: number) => Promise<void>;
 }

const SignalRContext = createContext<SignalRContextProps | undefined>(
   undefined
);


export const SignalRProvider = ({ children }: { children: ReactNode }) => {
    const { currentProfile } = useProfile(); // Get current profile from ProfileContext

   const [messages, setMessages] = useState<MessageModel[]>([]);
   const [conversations, setConversations] = useState<ConversationModel[] | undefined>(); // Initialize with the passed prop
   const [connection, setConnection] = useState<signalR.HubConnection | null>(
      null
   );
   var baseURL =
   process.env.NODE_ENV == "development"
      ? process.env.REACT_APP_URL_DEVELOPMENT
      : process.env.REACT_APP_URL_PRODUCTION;

      useEffect(() => {
        if (currentProfile) {
           // Set initial conversations if available
           setConversations(currentProfile.conversations);
        }
     }, [currentProfile]);
  

   useEffect(() => {
      const connection = new signalR.HubConnectionBuilder()
         .withUrl(`${baseURL}/chathub`)
         .withAutomaticReconnect()
         .build();

      setConnection(connection);

      connection
         .start()
         .then(() => {
            connection.on("ReceiveMessage", (newMessage: MessageModel) => {
               setMessages((prev) => [...prev, newMessage]);
               setConversations((prevConversations) =>
                  prevConversations?.map((conversation) =>
                     conversation.conversation_id === newMessage.conversation_id
                        ? {
                             ...conversation,
                             last_message_text: newMessage.message_text,
                             last_message_sender_id: newMessage.sender_id,
                             last_message_sent_time: newMessage.created_at,
                          }
                        : conversation
                  )
               );
            });
         })
         .catch((error) => console.error("SignalR connection failed:", error));
   }, []);

   const sendMessage = async (
      conversationId: number,
      messageText: string,
      senderId: number
   ) => {
      if (
         connection &&
         connection.state === signalR.HubConnectionState.Connected
      ) {

         await connection.send(
            "SendMessage",
            conversationId,
            messageText,
            "Sent",
            senderId
         );
      } else {
         console.error("No connection to server.");
      }
   };

   return (
      <SignalRContext.Provider
         value={{
            connection,
            messages,
            conversations,
            setConversations,
            sendMessage,
         }}
      >
         {children}
      </SignalRContext.Provider>
   );
};

export const useSignalR = () => {
   const context = React.useContext(SignalRContext);
   if (!context) {
      throw new Error("useSignalR must be used within a SignalRProvider");
   }
   return context;
};
