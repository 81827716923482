import "./global.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./Components/LandingPage";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ProfileProvider } from "./ProfileContext";
import { ComponentProvider } from "./CurrentComponent";
import { AvatarProvider } from "./AvatarContext";
import { SignalRProvider } from "./SignalRContext";

// Lazy load components
const LandingPage = React.lazy(() => import("./Components/LandingPage"));
const CreateAccount = React.lazy(
   () => import("./Components/CreateAccount/CreateAccount")
);
const Login = React.lazy(() => import("./Components/Login"));
const Profile = React.lazy(() => import("./Components/ProfileTab/Profile"));
const MainPage = React.lazy(() => import("./Components/MainPage"));
const Messages = React.lazy(() => import("./Components/Messages/Messages"));
const Register = React.lazy(() => import("./Components/Register"));

// Create a custom theme
const theme = createTheme({
   typography: {
      fontFamily: "Poppins, Arial, sans-serif", // Set Poppins as the default font family
   },
});

export default theme;
const root = ReactDOM.createRoot(
   document.getElementById("root") as HTMLElement
);
root.render(
   // <React.StrictMode>
   <Router>
      <ThemeProvider theme={theme}>
         <ComponentProvider>
            <Suspense fallback={<div>Loading...</div>}>
               <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/create-account" element={<CreateAccount />} />
                  <Route path="/login" element={<Login />} />
                  <Route
                     path="/main"
                     element={
                        <ProfileProvider>
                           <AvatarProvider>
                              <SignalRProvider>
                                 <MainPage />
                              </SignalRProvider>
                           </AvatarProvider>
                        </ProfileProvider>
                     }
                  />
                  <Route
                     path="/message"
                     element={
                        <ProfileProvider>
                           <Messages />
                        </ProfileProvider>
                     }
                  />
               </Routes>
            </Suspense>
         </ComponentProvider>
      </ThemeProvider>
   </Router>
   //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
