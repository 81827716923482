import React, { createContext, useContext, useState } from 'react';

interface ComponentContextProps {
    currentComponent: string;
    setCurrentComponent: React.Dispatch<React.SetStateAction<string>>;
}

const ComponentContext = createContext<ComponentContextProps | undefined>(undefined);

export const ComponentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentComponent, setCurrentComponent] = useState("find-others");

    return (
        <ComponentContext.Provider value={{ currentComponent, setCurrentComponent }}>
            {children}
        </ComponentContext.Provider>
    );
};

export const useComponentContext = () => {
    const context = useContext(ComponentContext);
    if (!context) {
        throw new Error("useComponentContext must be used within a ComponentProvider");
    }
    return context;
};
